<template>
  <div class="loginBox">
    <div class="left">
      <p class="logo"><img src="../../assets/images/logo.png" alt=""></p>
      <p class="img">
        <img src="../../assets/images/login_left_img.png" alt="">
      </p>
    </div>
    <div class="right">
      <div class="box">
        <el-tabs type="border-card" @tab-click="handleClick">
          <el-tab-pane label="手机号登录">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="top">
              <el-form-item label="手机号：" prop="mobile">
                <el-input placeholder="请输入手机号" v-model="ruleForm.mobile">
                  <template slot="prepend"> + 86</template>
                </el-input>
              </el-form-item>
              <el-form-item label="密码：" prop="password">
                <el-input v-model="ruleForm.password" placeholder="请输入登录密码" type="password"></el-input>
              </el-form-item>
            </el-form>
            <div class="mt-20 lBtn">
              <el-button type="primary" @click="loginSubmit()" size="mini">登录</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="新用户注册">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" class="demo-ruleForm1" label-position="top" label-width="80px">
              <el-form-item label="用户名：" prop="nick_name">
                <el-input placeholder="请输入用户名" v-model="ruleForm.nick_name">
                </el-input>
              </el-form-item>
              <el-form-item label="手机号：" prop="mobile">
                <el-input placeholder="请输入手机号" v-model="ruleForm.mobile">
                  <template slot="prepend"> + 86</template>
                </el-input>
              </el-form-item>
              <el-form-item label="密码：" prop="password">
                <el-input v-model="ruleForm.password" placeholder="请输入登录密码" type="password"></el-input>
              </el-form-item>
            </el-form>
            <div class="mt-20 lBtn">
              <el-button type="primary" @click="loginSubmit()" size="mini">{{ type == 1 ? '登录' : '注册' }}</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
  export default {
  name:'loginPage',
  data(){
    return {
      // 登录1 & 注册2
      type: 1,
      ruleForm: {
        nick_name:"",
        mobile: "",
        password: "",
      },
      rules: {
        nick_name: [{ required: true, message: '请填写用户名', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请填写手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度应在6到20个字符之间', trigger: 'blur' }
        ],
        }
      }
  },
  computed: {
    ...mapGetters(["getToken", "getLastPath", "getWxOpenId"])
  },
  created() {
        let that = this;
        document.onkeydown = function () {
            let key = window.event.keyCode;
            if (key == 13) {
                that.loginSubmit();
            }
        }
    },
  methods: {
    // 判断是否为微信浏览器
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
    handleClick(tab, event) {
      let refs = tab.index == 0 ? 'ruleForm' : "ruleForm1";
      this.type = tab.index + 1;
      this.$refs[refs].resetFields();
    },
    // 登录提交
    loginSubmit() {
      let { type } = this;
      let refs = type == 1 ? 'ruleForm' : "ruleForm1";
      this.$refs[refs].validate((valid) => { 
        if (valid) {
          let params = {
            mobile: this.ruleForm.mobile,
            password:this.ruleForm.password
          }
          if (this.isWeixinBrowser() && this.getWxOpenId) {
            params.wx_openid = this.getWxOpenId
          } else {
            delete params.wx_openid
          }

          if (type == 1) {
            this.$store.dispatch("login", params).then(res => { 
              if (res.code === 0) {
                //登录成功
                this.$store.commit('setLoginToken', res.data)
                let path =
                  !this.getLastPath || this.getLastPath === "/login" || this.getLastPath === "/wxLogin"
                    ? "/"
                    : this.getLastPath;
                this.$router.push(path);
              } else {
                this.$notify({
                  title: '警告',
                  message: res.message
                });
              }
            })
          }else{
            params.nick_name = this.ruleForm.nick_name;
            this.$store.dispatch("register", params).then(res => {
              if (res.code === 0) {
                _this.$store.commit('setLoginToken', res.data)
                let path =
                  !this.getLastPath || this.getLastPath === "/login" || this.getLastPath === "/wxLogin"
                    ? "/"
                    : this.getLastPath;
                this.$router.push(path);
              } else if(res.code === 10003) {
                this.$notify({
                  title: "警告",
                  message: res.message,
                  type: "warning"
                });
              }
            })
          }
        }
      })
    },
  }
  }
</script>

<style lang="scss" scoped>
</style>
